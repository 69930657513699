@border-radius:30px;
@main-color:#0098cb;

.navbar{
	margin-bottom:0px;
}

body{
	background:#fff;
}

#chatter{

	padding-bottom:30px;

	.margin-top{
		margin-top:40px;
	}

	.btn:focus{
		outline:none;
	}

	button.btn.btn-primary{
		border: 2px solid #f1f5ff;
	    background: none;
	    color: #0098cb;
	    &:hover, &:active, &:focus{
	    	outline:none;
	    }
	}

	#chatter_hero{
		background-image:url('../images/hero_bg.jpg');
		width:100%;
		min-height:150px;
		position:relative;
		background-size:cover;
		background-position:center center;
		text-align:center;

		img{
			max-height: 60px;
    		margin: 45px auto;
    		position:relative;
		}

		#chatter_hero_dimmer{
			
			background:rgba(0, 0, 0, 0.2);
			position:absolute;
			width:100%;
			height:100%;
			left:0px;
			top:0px;
		}

		h1{
			text-align:center;
			position:relative;
			color:#ffffff;
			margin-top:0px;
			padding-top: 40px;
			margin-bottom:10px;
		}

		p{
			text-align:center;
			color:#cccccc;
			position:relative;
			z-index:2;
			margin-bottom:0px;
		}

	}

	.chatter_container{
		margin-top:40px;
	}

	#title.form-control{
		height:48px;
	}

	.form-control{
    	font-size: 16px;
    	border:0px;
    	box-shadow:0px 0px 0px;
    	border-radius:0px;
	}

	#new_discussion{
		display:none;
		border: 2px solid @main-color;
		border-bottom:0px;
	    position: fixed;
	    bottom: 0px;
	    width:750px;
	    margin-left:-375px;
	    left:50%;
	    height:auto;
	    background: #fff;
	    z-index: 9999;
	    border-top-right-radius:5px;
	    border-top-left-radius:5px;
	    overflow:hidden;

	    form{
	    	display:none;
	    }

	    #new_discussion_footer{
	    	border-top:1px solid #f3f6f9;
	    	padding:10px 15px;
	    }

	    .chatter-close{
	    	color: #7089A9;
		    position: relative;
		    top: 17px;
		    border-radius: 12px;
		    background: rgba(0, 0, 0, 0.05);
		    padding: 6px;
		    padding-bottom: 3px;
		    right: 5px;
		    cursor:pointer;
		    transition:background 0.3s ease, color 0.3s ease;
		    &:hover{
		    	background: rgba(0, 0, 0, 0.1);
		    }
	    }

	    .select_color_text{
	    	color:#ddd;
	    	font-size:12px;
	    	margin-left:10px;
	    }

	    /********** COLOR PICKER STYLE OVERRIDES **********/

		.sp-preview-inner, .sp-alpha-inner, .sp-thumb-inner{
		    border-radius: @border-radius;
		    height: 26px;
		    width: 26px;
		}

		.sp-preview{
			width:28px;
			height:28px;
			border-color:#7089A9;
			border-radius:@border-radius;
		}

		.sp-replacer{
			border-radius:@border-radius;
			border: solid 2px #f1f5f9;
    		background: none;
		}

		.sp-dd{
			margin-right:4px;
			line-height:0px;
			-webkit-transform: rotate(180deg);
		    -moz-transform: rotate(180deg);
		    -o-transform: rotate(180deg);
		    -ms-transform: rotate(180deg);
		    transform: rotate(180deg);
		    color: #7089A9;
		    top: 3px;
		    padding-left: 5px;
		}

		/********** END COLOR PICKER STYLE OVERRIDES **********/
	}

	#chatter_header{
		background:#263238;
		width:100%;
		min-height:80px;

		h1{
			margin-top:0px;
			margin-bottom:0px;
			line-height:80px;
			font-size:24px;
			color:#f1f1f1;
			float:left;
		}

		span{
			float:right;
			line-height:80px;
			margin-left:10px;
			color:#ccc;
			color:rgba(255, 255, 255, 0.85);
			font-size:12px;
		}

		a.chatter_cat{
			border-radius:15px;
			color:#fff;
			padding:5px 10px;
			margin-left:10px;
			&:hover{
				text-decoration:none;
			}
		}

		a.back_btn{
			float: left;
		    line-height: 40px;
		    font-size: 15px;
		    margin-right: 10px;
		    border-radius: 50%;
		    padding: 0px;
		    background: rgba(0, 0, 0, 0.2);
		    width: 34px;
		    height: 34px;
		    text-align: center;
		    margin-top: 23px;
		    color: rgba(255, 255, 255, 0.8);
		    transition:background 0.3s ease, color 0.4s ease;

		    &:hover{
		    	text-decoration:none;
		    	background: rgba(0, 0, 0, 0.4);
		    	color:#ffffff;
		    }

		    &:focus, &:active{
		    	text-decoration:none;
		    }

		    i{
		    	position:relative;
		    	left:-1px;
		    }
		}
	}

	.btn{
		i{
			position: relative;
			top: 2px;
		}
		border:0px;
		border-radius:@border-radius;
		&:hover, &:visited, &:active{
			border:0px;
			outline:none;
		}
	}

	.left-column{
		>a.btn{
			margin-bottom:15px;
			border-radius:@border-radius;
		}
		.btn{
			width:100%;
		}
		.nav-pills>li>a{
			padding:5px;
			padding-left:30px;
			padding-right:0px;
			position:relative;
			.chatter-box{
				width:10px;
				height:10px;
				border-radius:2px;
				float:left;
				position: absolute;
				top: 50%;
				margin-top: -5px;
				left:10px;
			}
			
		}
		.nav-pills>li>a:focus, .nav-pills>li>a:hover{
			background:none;
		}
	}

	.chatter_avatar{
		float:left;
		margin:5px;
		margin-right:15px;
		position:absolute;
		left:10px;

		span.chatter_avatar_circle{
			width: 60px;
		    height: 60px;
		    line-height: 62px;
		    text-align: center;
		    background: #263238;
		    display: inline-block;
		    border-radius: 30px;
		    color: #fff;
		    font-size: 20px;
		}

		img{
			width:60px;
			height:60px;
			border-radius:50%;
		}
	}

	
	ul.discussions{
		list-style:none;
		margin:0px;
		padding:0px;
		background:#fff;

		&.no-bg{
			background:none;
		}

		li{

			a.discussion_list, .chatter_posts{

				background:#ffffff;
				padding:15px;
				transition:background 0.3s ease;
				border-top: 1px solid #f1fcff;
				text-decoration:none;
				display:block;
				position:relative;

				.chatter_warning_delete{
					background: #f1f1f1;
				    z-index: 999;
				    display:none;
				    position: relative;
				    padding: 12px 15px;
				    margin-bottom: 20px;
				    border-radius: 30px;
				    color: #737B7F;

				    i{
				    	position:relative;
				    	top:2px;
				    	margin-right:10px;
				    }

				    .btn{
				    	position:relative;
				    	top:-3px;
				    }
				    .btn-default{
				    	margin-right:5px;
				    }
				    .btn-danger{
				    	background:#D50105;
				    }
				}

				.chatter_post_actions{
					position:absolute;
					right:0px;
					top:15px;
					
					p{
						margin:0px;
						float:right;
						background: #f1fcff;
						border-radius: 10px;
					    font-size: 12px;
					    cursor: pointer;
					    padding: 3px 10px;
					    margin-right: 10px;
					    color: #B1BcBf;
					    transition:color 0.3s linear;
						&:hover{
							color: #737B7F;
						}
						i{
							position:relative;
							top:2px;
						}
					}
				
				}			

				.chatter_middle{
					float:left;
					margin-left:80px;
					margin-right:90px;
					h3.chatter_middle_title{
						margin:0px;
						padding:0px;
						color: #111;
						transition:color 0.3s ease;
    					font-size: 18px;
    					font-weight: bold;
    					margin-top:5px;
    					.chatter_cat{
							background:#ccc;
							border-radius:@border-radius;
							font-weight:bold;
							font-size:10px;
							padding:3px 7px;
							display:inline;
							color:#fff;
							position:relative;
							top:-2px;
						}
					}
					span.chatter_middle_details{
						font-size:10px;
						color:#9DADC4;
						transition:color 0.3s ease;
					}

					.chatter_body{
						.chatter_body_md{
							display:none;
						}
						padding-top:5px;
						pre{
							padding: 16px;
						    overflow: auto;
						    line-height: 1.45;
						    background-color: #f7f7f7;
						    border-radius: 3px;
						    border:0px;
						    margin:20px auto;
						}
					}
					
				}

				.chatter_right{
					float:right;
					right:25px;
					text-align:right;
					position:absolute;
					
					.chatter_count{
						padding: 8px 20px;
					    border-radius: 3px;
					    margin-top: 10px;
					    font-size: 20px;
					    color: #9DADB4;
					    transition:color 0.3s ease;
					    font-family:Arial, Helvetica, Times, Courier;
					    i{
					    	position:relative;
					    	top:2px;
					    }
					}
				}

				.chatter_clear{
					clear:both;
				}

				
			}

			a{
				&:hover{
					background:#F3F6F9;
					text-decoration:none;
					.chatter_middle{
						h3{
							color:#000;
						}
						span{
							color:#7D8DA4;
						}
						p{
							margin-bottom:0px;
							padding-bottom:0px;
							color:#888;
						}
					}

					.chatter_right{
						.chatter_count{
							color: #7D8D94;
						}
					}
				}
			}

			.chatter_posts{
				margin-bottom:15px;
				

				.chatter_middle{
					&>span{
						font-weight:bold;
						display:block;
						padding-bottom:10px;
						a{
							font-weight:bold;
							color:#131619;
							font-size:14px;
						}
						.ago{
							font-size:14px;
							color:#7089a9;
							padding-left:6px;
							font-weight:400;
						}
					}
					.chatter_middle_details{
						a{
							display:inline-block;
							background:none;
							padding:0;
							text-decoration:none;	
							border-top:0px;				
						}
					}
					
					p{
						font-size:14px;
						color:#151a1f;
					}
				}
			}

			&.editing{
				overflow:hidden;
				.chatter_middle_details, .chatter_body, .chatter_post_actions, .chatter_middle > span.chatter_middle_details a{
					display:none;
				}
				.mce-toolbar-grp.mce-container.mce-panel{
					padding-top:0px;
				}
				.mce-stack-layout-item{
					border-top:0px !important;
				}
				textarea{
					width:100%;
				}
				.chatter_middle{
					width: 100%;
    				padding-right: 80px;
				}
				.chatter_update_actions{
					background: #fcfcfc;
				    display: block;
				    height: 50px;
				    padding: 7px;
				    top: -5px;
				    position: relative;
				    border: 1px solid #f1f1f1;
    				border-top: 0px;
    				.cancel_chatter_edit{
    					margin-right:5px;
    				}
				}
			}

			&.delete_warning{
				.chatter_post_actions{
					top:75px;
				}
			}


		}

	}

	&.chatter_home{
		.chatter_middle{
			p{
				margin-bottom:0px;
				padding-bottom:0px;
				color:#aaa;
				transition:color 0.3s ease;
				font-size:12px;
			}
		}
	}

	.conversation{
		border-bottom:1px solid #f1fcff;
		margin-bottom:20px;
		ul.discussions{
			li{
				.chatter_posts{
					.chatter_middle{
						margin-left:0px;
						padding-left:80px;
						padding-right:0px;
						width:100%;
					}
				} 
			}
		}
	}

	#cancel_discussion{
		margin-right:15px;
	}

	#editor{
		#tinymce_placeholder{
			position: absolute;
			top: 89px;
			left: 0px;
			padding: 8px 14px;
			width: 96%;
			overflow: hidden;
			white-space: pre-wrap;
			color: #ccc;
			font-weight: normal;
			z-index:9999;
			cursor:text;
			display:none;
		}
		#body{
			display:none;
		}
		&.loaded{
			#body{
				display:block;
			}
			.chatter_loader{
				display:none;
			}
		}

		>.mce-container.mce-panel{
			border-width:0px !important;
		}

		
	}



	#chatter_category_id{
		height:50px;
		color:#777;
		position:relative;
		left:-10px;
	}

	.chatter_cat{
		padding-left:10px;
		padding-right:10px;
	}

	.chatter-alert{
		width:100%;
		margin-bottom:0px;
		border-radius:0px;
		&.alert-success{
			background:#55B559;
			border:0px;
			color:#fff;
		}
		&.alert-info{
			background:#0ab1fc;
			border:0px;
			color:#fff;
		}
		&.alert-warning{
			background:#FBC53C;
			border:0px;
			color:#fff;
		}
		&.alert-danger{
			background:#F55145;
			border:0px;
			color:#fff;
		}
		.chatter-close{
			float: right;
		    position: relative;
		    top: 1px;
		    cursor:pointer;
		}
	}

	.chatter_sidebar{
		&>a{
			padding:20px;
			display:block;

			&:hover{
				text-decoration:none;
			}
		}

		ul{
			margin-left:10px;
		}
	}

	/********** Styles specific for discussion page **********/


	&.discussion{ 

		#editor{
			.mce-toolbar-grp{
				padding:0px;
			}
			.mce-toolbar{
				border-top:0px !important;
			}
			#tinymce_placeholder{
				top:35px;
			}
		}

		#new_response{
			padding-left:100px;
			padding-right:15px;
		
			.chatter_avatar{
				margin-left:20px;
			}

			#new_discussion{
				display:block;
				position:relative;
				left:0px;
				margin-left:0px;
				bottom:0px;
				margin-top:0px;
				border: 1px solid #f1f5ff;
				width:100%;
				border-radius:2px;
			}
		}

		ul.discussions li .chatter_posts .chatter_middle > span{
			padding-bottom:0px;
		}

		span.chatter_middle_details{
			padding-top:5px;
		}

		.chatter_middle{
			margin-right:0px !important;
		}

		#login_or_register{
			p{
				text-align:center;
			}
		}

		#submit_response{
			margin-top:20px;
		}

	}

	/********** PAGINATION **********/
	#pagination{
		text-align:center;
	}

	/********** LOADER **********/

	.chatter_loader_container{
		background:#fff;
		border-radius:3px;
		padding:2px;
		width:100%;
	}
	.chatter_loader,
	.chatter_loader > div {
	    position: relative;
	    -webkit-box-sizing: border-box;
	       -moz-box-sizing: border-box;
	            box-sizing: border-box;
	}
	.chatter_loader {
	    display: block;
	    font-size: 0;
	    margin:20px auto;
	    color: #fff;
	    &.loaded{
	    	display:none;
	    }
	}
	.chatter_loader.dark {
	    color: @main-color;
	}
	.chatter_loader > div {
	    display: inline-block;
	    float: none;
	    background-color: currentColor;
	    border: 0 solid currentColor;
	}
	.chatter_loader {
	    width: 32px;
	    height: 32px;
	}
	.chatter_loader > div {
	    width: 32px;
	    height: 32px;
	    background: transparent;
	    border-width: 2px;
	    border-bottom-color: transparent;
	    border-radius: 100%;
	    -webkit-animation: ball-clip-rotate .75s linear infinite;
	       -moz-animation: ball-clip-rotate .75s linear infinite;
	         -o-animation: ball-clip-rotate .75s linear infinite;
	            animation: ball-clip-rotate .75s linear infinite;
	}
	.chatter_loader.la-sm {
	    width: 16px;
	    height: 16px;
	}
	.chatter_loader.la-sm > div {
	    width: 16px;
	    height: 16px;
	    border-width: 1px;
	}
	.chatter_loader.la-2x {
	    width: 64px;
	    height: 64px;
	}
	.chatter_loader.la-2x > div {
	    width: 64px;
	    height: 64px;
	    border-width: 4px;
	}
	.chatter_loader.la-3x {
	    width: 96px;
	    height: 96px;
	}
	.chatter_loader.la-3x > div {
	    width: 96px;
	    height: 96px;
	    border-width: 6px;
	}
	/*
	 * Animation
	 */
	@-webkit-keyframes ball-clip-rotate {
	    0% {
	        -webkit-transform: rotate(0deg);
	                transform: rotate(0deg);
	    }
	    50% {
	        -webkit-transform: rotate(180deg);
	                transform: rotate(180deg);
	    }
	    100% {
	        -webkit-transform: rotate(360deg);
	                transform: rotate(360deg);
	    }
	}
	@-moz-keyframes ball-clip-rotate {
	    0% {
	        -moz-transform: rotate(0deg);
	             transform: rotate(0deg);
	    }
	    50% {
	        -moz-transform: rotate(180deg);
	             transform: rotate(180deg);
	    }
	    100% {
	        -moz-transform: rotate(360deg);
	             transform: rotate(360deg);
	    }
	}
	@-o-keyframes ball-clip-rotate {
	    0% {
	        -o-transform: rotate(0deg);
	           transform: rotate(0deg);
	    }
	    50% {
	        -o-transform: rotate(180deg);
	           transform: rotate(180deg);
	    }
	    100% {
	        -o-transform: rotate(360deg);
	           transform: rotate(360deg);
	    }
	}
	@keyframes ball-clip-rotate {
	    0% {
	        -webkit-transform: rotate(0deg);
	           -moz-transform: rotate(0deg);
	             -o-transform: rotate(0deg);
	                transform: rotate(0deg);
	    }
	    50% {
	        -webkit-transform: rotate(180deg);
	           -moz-transform: rotate(180deg);
	             -o-transform: rotate(180deg);
	                transform: rotate(180deg);
	    }
	    100% {
	        -webkit-transform: rotate(360deg);
	           -moz-transform: rotate(360deg);
	             -o-transform: rotate(360deg);
	                transform: rotate(360deg);
	    }
	}

	/********** END LOADER **********/

}

/********** MORE COLOR PICKER STYLE OVERRIDES **********/
.chatter-color-picker{
	background: #ffffff;
    border: 2px solid #f1f5f9;
    border-radius: 10px;
    margin-top: -5px;
	.sp-color, .sp-hue, .sp-clear{
		border-color:#ddd;
	}
	
	.sp-cancel{
		display:none;
		color:#aaa !important;
	}

}
.sp-container, .sp-container button, .sp-container input, .sp-color, .sp-hue, .sp-clear{
	font-family:inherit;
}
.sp-container button.sp-choose, .sp-container button.sp-choose:hover, .sp-container button.sp-choose:active, .sp-container button.sp-choose:focus{
	background: none;
	border: 1px solid #e5e9ef;
	color: #ccc;
	font-weight: normal;
	font-size: 12px;
	margin-top: 3px;
	outline:none;
	box-shadow:0px 0px 0px;
	text-shadow:0px 0px 0px;
}
.sp-container button:hover{
	text-shadow:0px 0px 0px;
}
/********** END MORE COLOR PICKER STYLE OVERRIDES **********/

/********** TINYMCE Modifications **********/

#tinymce{
	background:#ffffff !important;
	padding:10px 5px;
}

.mce-panel.mce-floatpanel{
	border-radius:3px;
	border-top-right-radius:5px;
	border-top-left-radius:5px;
	border:0px;
	max-height:530px;
}

#mceu_23.mce-panel.mce-floatpanel{
	top:50% !important;
	margin-top:-265px !important;
}

.mce-window-head{
	background:@main-color !important;
	border-top-left-radius:3px;
	border-top-right-radius:3px;
	border:1px solid @main-color;
}

.mce-foot{
	background-color: #f1f1f1 !important;
    border: 0px !important;
    position:absolute;
    bottom:0px;
}

.mce-window-head .mce-title{
	color:#fff;
	font-size:14px !important;
}

.mce-container-body.mce-abs-layout{
	max-height:440px;
}

.mce-panel.mce-floatpanel.mce-menu, .mce-btn:focus, .mce-btn:active, .mce-btn:hover{
	border:1px solid #f1f1f1;
	border-color:#f1f1f1 !important;
}

div.mce-edit-area{
	padding-left:8px;
	padding-right:8px;
	border:0px;
}

.mce-toolbar{
	border-top:1px solid #f3f6f9 !important;
	border-bottom:1px solid #f3f6f9 !important;
}

.mce-textbox.mce-multiline{
	height:350px !important;
	resize: vertical !important;
}

#mce-modal-block{
	background:#21292f !important;
}

/********** END TINYMCE Modifications **********/


/********** START SimpleMDE Overrides **********/

#editor .editor-toolbar, .chatter_middle .editor-toolbar{
	border-radius: 0px;
    border-color: #eee;
    a{
    	outline:none;
    	&:focus{
    		outline:none;
    	}
    }
}

#editor .CodeMirror, .chatter_middle .CodeMirror{
	height:220px;
	min-height:220px;
	border-radius: 0px;
    border-color: #eee;
}

#editor .CodeMirror-scroll, .chatter_middle .CodeMirror-scroll{
	min-height:204px;
}

.simplemde{
	#chatter{
		#new_discussion{
			width: 100%;
		    height: 100%;
		    overflow: visible;
		}
	}
}

#chatter{
	#new_response{
		#new_discussion{
			z-index:3;
		}
	}
}

#chatter{
	.editor-toolbar.fullscreen{
		z-index:9999;
	}
}
/********** END SimpleMDE Overrides **********/


/********** FONTS USED FOR CHATTER **********/

@charset "UTF-8";

@font-face {
  font-family: "chatter";
  src:url("../fonts/chatter.eot");
  src:url("../fonts/chatter.eot?#iefix") format("embedded-opentype"),
    url("../fonts/chatter.woff") format("woff"),
    url("../fonts/chatter.ttf") format("truetype"),
    url("../fonts/chatter.svg#chatter") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "chatter" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="chatter-"]:before,
[class*=" chatter-"]:before {
  font-family: "chatter" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.chatter-bubble:before {
  content: "\61";
}
.chatter-thumbtack:before {
  content: "\62";
}
.chatter-check:before {
  content: "\63";
}
.chatter-new:before {
  content: "\64";
}
.chatter-back:before {
  content: "\65";
}
.chatter-close:before {
  content: "\66";
}
.chatter-delete:before {
  content: "\69";
}
.chatter-edit:before {
  content: "\68";
}
.chatter-warning:before {
  content: "\67";
}


/********** END FONTS USED FOR CHATTER **********/


/********** START Toggle Switch CSS **********/

#notify_email{
	border-radius:30px;
	height:34px;
	width:auto;
	padding-left:10px;
	background:#f1f1f1;
	float:right;
	margin-top: 20px;
    margin-right: 10px;
    padding-left: 15px;
    color: #aaa;
    > span{
    	line-height:35px;
    	-webkit-user-select: none;
		-moz-user-select: none;
		-khtml-user-select: none;
		-ms-user-select: none;
    }

    .chatter_email_loader{
    	display:none;
    	position: relative;
		top: -1px;
		left: -4px;
		&.loading{
			display:block;
		    float: left;
		    top: 9px;
		}
    }

	/* The switch - the box around the slider */
	.switch {
		position: relative;
		display: inline-block;
		width: 56px;
		height: 28px;
		top:2px;
		right:3px;
		float:right;
		margin-left:15px;

		/* Hide default HTML checkbox */
		input {
			display:none;
		}

		span{
			cursor:pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-khtml-user-select: none;
			-ms-user-select: none;
		}

	  	/* The slider */
		.slider {
		  position: absolute;
		  cursor: pointer;
		  top: 0;
		  left: 0;
		  right: 0;
		  bottom: 0;
		  background-color: #ccc;
		  -webkit-transition: .4s;
		  transition: .4s;
		}

		.slider:before {
		  position: absolute;
		  content: "";
		  height: 20px;
		  width: 20px;
		  left: 6px;
		  top: 4px;
		  background-color: white;
		  -webkit-transition: .4s;
		  transition: .4s;
		}

		input:checked ~ .slider {
		  background-color: #27ae60;
		}

		input:not(:checked) ~ span.on{
			display:none;
		}

		input:checked ~ span.on {
			position: absolute;
		    z-index: 9;
		    color: #fff;
		    top:3px;
		    left:6px;
		}

		input:checked ~ span.off {
			display:none;
			transition:opacity 1s linear;
		}

		input:not(:checked) ~ span.off{
			display: block;
		    right: 7px;
		    position: absolute;
		    color: #999;
		    z-index: 9;
		    font-weight: bold;
		    top: 3px;
		    opacity:1;

		}

		input:focus ~ .slider {
		  box-shadow: 0 0 1px #2196F3;
		}

		input:checked ~ .slider:before {
		  -webkit-transform: translateX(26px);
		  -ms-transform: translateX(26px);
		  transform: translateX(26px);
		}

		/* Rounded sliders */
		.slider.round {
		  border-radius: 34px;
		}

		.slider.round:before {
		  border-radius: 50%;
		}

	}
}

/********** End Toggle Switch CSS **********/

#pagination{
	font-size:18px;
	.pagination>.active>span{
		background: none;
	    color: #333;
	    font-weight: bold;
	    border:0px;
	}
	.pagination>.disabled>span{
		border:0px;
		color:#ccc;
	}
	.pagination>li>a, .pagination>li>span{
		border: 0px;
    	padding: 6px 5px;
    	color:#777;
	}
	.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover{
		background: none;
    	font-weight: bold;
	}
}